





















import { Prop } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";

import { ProjectMixin } from "@/mixins/project.mixin";

import { Project } from "@/interfaces/project";

@Component
export default class ProjectsSwiperSlide extends mixins(ProjectMixin) {
  /** Project company name */
  @Prop() project!: Project;
}
